import { RedocStandalone } from 'redoc'

const Home = () => {
	return (
		<RedocStandalone
			specUrl="/openapi.json"
			options={{
				theme: {
					logo: {
						gutter: '24px',
					},
				},
			}}
		/>
	)
}

export default Home
