import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Main from './layouts/Main'

import Home from './pages/Home'

const AppRouter = () => (
	<Routes>
		<Route element={<Main />}>
			<Route exact path="/" element={<Home />} />
		</Route>
	</Routes>
)

export default AppRouter
