/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
	aws_project_region: 'eu-central-1',
	aws_cognito_identity_pool_id: '',
	aws_cognito_region: 'eu-central-1',
	aws_user_pools_id: '',
	aws_user_pools_web_client_id: '',
	oauth: {},
	federationTarget: 'COGNITO_USER_POOLS',
}

export default awsmobile
